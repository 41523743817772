import React from 'react';
import { graphql } from 'gatsby';
import { SEO } from 'src/components/common';
import { ListPage } from 'src/components/post';
import type { PageProps } from 'gatsby';
import type { GetPlacePostsQuery } from 'types/graphql-type';
import type { PagePlaceContextType } from 'types/custom-type';

type Props = PageProps<GetPlacePostsQuery, PagePlaceContextType>;

export const query = graphql`
  query GetPlacePosts($place: String!) {
    posts: allPrismicPost(
      limit: 100
      sort: { fields: [data___date], order: DESC }
      filter: {
        data: { places: { elemMatch: { place: { uid: { eq: $place } } } } }
      }
    ) {
      ...PostList
    }
  }
`;

const Place: React.FC<Props> = ({
  pageContext: { place, placeLabel },
  data: { posts },
  location: { pathname },
}) => (
  <>
    <SEO title={`開催プログラム Place: ${placeLabel}`} pathname={pathname} />
    {posts && <ListPage posts={posts} place={place} />}
  </>
);

export default Place;
